var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center",attrs:{"href":"#"},on:{"click":_vm.confirmationBack}},[_c('i',{staticClass:"mdi mdi-chevron-left"}),_vm._v(" Kembali ")]),_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"position":"fixed","top":"2rem","z-index":"1000000"}},[_c('a',{staticClass:"btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2 border",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-primary svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Clock.svg"}})],1),(this.now >= this.currentAttempt.target)?_c('span',[_vm._v(" 00:00:00 ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("two_digits")(_vm.hours))+":"+_vm._s(_vm._f("two_digits")(_vm.minutes))+":"+_vm._s(_vm._f("two_digits")(_vm.seconds))+" ")])]),_c('span',{staticClass:"btn btn-fh btn-light-info btn-hover-info border border-info font-weight-bold px-2 px-lg-5 mr-2",on:{"click":_vm.submitUjian}},[_c('span',{staticClass:"svg-icon svg-icon-info svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Save.svg"}})],1),_vm._v(" Selesai ")])])])],1),_c('b-row',{staticClass:"h-100 mt-10"},[_c('b-col',{attrs:{"xs":"12","lg":"9","md":"12"}},[_c('div',{class:`card card-custom card-stretch gutter-b`},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column justify-content-center"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Soal "+_vm._s(_vm.selectedIndex + 1)+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('a',{class:`btn btn-icon btn-light-info btn-sm ${
                _vm.selectedQuestion.flagged ? 'active' : ''
              }`,on:{"click":_vm.changeFlagged}},[_c('span',{class:`svg-icon svg-icon-md ${
                  _vm.selectedQuestion.flagged ? 'svg-icon-light' : ''
                }`},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Flag.svg"}})],1)])])]),_c('div',{staticClass:"card-body d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1 pb-5"},[_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.selectedQuestion.question)}}),(_vm.selectedQuestion.type == 'multiple answer')?_c('div',{staticClass:"text-right mb-2"},[_vm._v(" [Pilih "+_vm._s(_vm.selectedQuestion.type == 'multiple answer' ? _vm.selectedQuestion.count_correct_answer : 0)+" jawaban] ")]):_vm._e(),_c('div',{staticClass:"jawaban"},[(_vm.selectedQuestion.type != 'essay')?_vm._l((_vm.selectedQuestion.answer),function(jawaban,i){return _c('div',{key:`${_vm.selectedQuestion._id}${i}`,class:`card card-custom card-stretch ${
                    _vm.selectedQuestion.type == 'multiple answer' &&
                    _vm.selectedQuestion.answer.filter((item) => item.selected)
                      .length == _vm.selectedQuestion.jawaban_count &&
                    !jawaban.selected
                      ? ''
                      : 'cursor-pointer bg-hover-light-primary text-hover-primary'
                  } border ${
                    jawaban.selected
                      ? 'bg-light-primary text-primary border-primary'
                      : ''
                  }`,on:{"click":function($event){_vm.selectedQuestion.type == 'multiple answer' &&
                    _vm.selectedQuestion.answer.filter((item) => item.selected)
                      .length == _vm.selectedQuestion.count_correct_answer &&
                    !jawaban.selected
                      ? null
                      : _vm.changeJawaban(jawaban, _vm.selectedQuestion)}}},[_c('div',{staticClass:"card-body d-flex flex-row p-4 align-items-center"},[(_vm.selectedQuestion.type == 'multiple answer')?_c('b-form-checkbox',{staticClass:"align-items-start cursor-pointer",attrs:{"disabled":"","name":`jawaban${_vm.selectedQuestion._id}`,"size":"lg"},model:{value:(jawaban.selected),callback:function ($$v) {_vm.$set(jawaban, "selected", $$v)},expression:"jawaban.selected"}}):_c('b-form-radio',{staticClass:"align-self-start cursor-pointer",attrs:{"name":`jawaban${_vm.selectedQuestion._id}`,"value":jawaban._id,"size":"lg"},model:{value:(_vm.selectedQuestion.id_jawaban),callback:function ($$v) {_vm.$set(_vm.selectedQuestion, "id_jawaban", $$v)},expression:"selectedQuestion.id_jawaban"}}),_c('div',{domProps:{"innerHTML":_vm._s(jawaban.answer)}})],1)])}):_vm._e(),(_vm.selectedQuestion.type == 'essay')?_c('b-form-group',{attrs:{"label":"Jawaban"}},[_c('b-form-textarea',{attrs:{"id":`textarea-jawaban-${_vm.i}`,"rows":"3"},on:{"input":_vm.persist},model:{value:(_vm.selectedQuestion.jawaban),callback:function ($$v) {_vm.$set(_vm.selectedQuestion, "jawaban", $$v)},expression:"selectedQuestion.jawaban"}})],1):_vm._e()],2)]),_c('div',[_c('b-row',{staticClass:"justify-content-between"},[_c('b-col',[(_vm.previousVisible)?_c('b-button',{on:{"click":_vm.previous}},[_vm._v(" previous ")]):_vm._e()],1),_c('b-col',{staticClass:"row justify-content-end"},[(_vm.nextVisible)?_c('b-button',{on:{"click":_vm.next}},[_vm._v(" next ")]):_vm._e()],1)],1)],1)])])]),_c('b-col',[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.questions),function(soal,i){return _c('b-col',{key:soal._id,staticClass:"m-2 text-center",attrs:{"cols":"2"}},[_vm._v("   "),_c('span',{class:`btn btn-icon btn-sm symbol ${
                  _vm.selectedQuestion._id == soal._id
                    ? 'btn-light-warning active'
                    : (
                        soal.type == 'essay'
                          ? soal.jawaban !== undefined
                            ? soal.jawaban.length > 0
                            : false
                          : soal.answer.filter((item) => item.selected)
                              .length > 0
                      )
                    ? soal.flagged
                      ? 'btn-light-info active'
                      : 'btn-light-primary' + ' active'
                    : soal.flagged
                    ? 'btn-light-info active'
                    : 'btn-secondary'
                }`,on:{"click":function($event){return _vm.changeSoal(i)}}},[_c('span',{class:`svg-icon svg-icon-md ${
                    soal.flagged ? 'svg-icon-light' : ''
                  }`},[_vm._v(" "+_vm._s(i + 1)+" ")])])])}),1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }